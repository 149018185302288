// modelLinks.js
export const modelLinks = {
    "chatgpt-4o-latest-0903": "https://openai.com/index/hello-gpt-4o/",
    "claude-3-5-sonnet-20240620": "https://www.anthropic.com/news/claude-3-5-sonnet",
    "claude-3-haiku-20240307": "https://www.anthropic.com/claude",
    "claude-3-opus-20240229": "https://www.anthropic.com/claude",
    "claude-3-sonnet-20240229": "https://www.anthropic.com/claude",
    "command-r": "https://docs.cohere.com/docs/models",
    "command-r-08-2024": "https://docs.cohere.com/docs/models",
    "command-r-plus": "https://docs.cohere.com/docs/models",
    "command-r-plus-08-2024": "https://docs.cohere.com/docs/models",
    "deepseek-coder-v2": "https://huggingface.co/deepseek-ai/DeepSeek-V2",
    "deepseek-v2.5": "https://huggingface.co/deepseek-ai/DeepSeek-V2.5",
    "dracarys-72b-instruct": "https://huggingface.co/abacusai/Dracarys-72B-Instruct",
    "dracarys-llama-3.1-70b-instruct": "https://huggingface.co/abacusai/Dracarys-Llama-3.1-70B-Instruct",
    "dracarys2-72b-instruct": "https://huggingface.co/abacusai/Dracarys2-72B-Instruct",
    "dracarys2-llama-3.1-70b-instruct": "https://huggingface.co/abacusai/Dracarys2-Llama-3.1-70B-Instruct",
    "gemini-1.5-flash-002": "https://developers.googleblog.com/en/updated-production-ready-gemini-models-reduced-15-pro-pricing-increased-rate-limits-and-more/",
    "gemini-1.5-flash-8b-exp-0827": "https://ai.google.dev/gemini-api/docs/models/experimental-models",
    "gemini-1.5-flash-api-0514": "https://console.cloud.google.com/vertex-ai/publishers/google/model-garden/gemini-1.5-flash-preview-0514",
    "gemini-1.5-pro-002": "https://developers.googleblog.com/en/updated-production-ready-gemini-models-reduced-15-pro-pricing-increased-rate-limits-and-more/",
    "gemini-1.5-pro-api-0514": "https://console.cloud.google.com/vertex-ai/publishers/google/model-garden/gemini-1.5-flash-preview-0514",
    "gemini-1.5-pro-exp-0801": "https://ai.google.dev/gemini-api/docs/models/experimental-models",
    "gemini-1.5-pro-exp-0827": "https://ai.google.dev/gemini-api/docs/models/experimental-models",
    "gemma-2-27b-it": "https://huggingface.co/google/gemma-2-27b",
    "gemma-2-9b-it": "https://huggingface.co/google/gemma-2-9b",
    "gpt-3.5-turbo-0125": "https://openai.com/index/new-embedding-models-and-api-updates/",
    "gpt-4-0125-preview": "https://openai.com/index/new-models-and-developer-products-announced-at-devday/",
    "gpt-4-0613": "https://openai.com/index/new-models-and-developer-products-announced-at-devday/",
    "gpt-4-turbo-2024-04-09": "https://openai.com/index/new-models-and-developer-products-announced-at-devday/",
    "gpt-4o-2024-05-13": "https://openai.com/index/hello-gpt-4o/",
    "gpt-4o-2024-08-06": "https://openai.com/index/hello-gpt-4o/",
    "gpt-4o-mini-2024-07-18": "https://openai.com/index/hello-gpt-4o/",
    "meta-llama-3.1-405b-instruct-turbo": "https://www.together.ai/blog/meta-llama-3-1",
    "meta-llama-3.1-70b-instruct-turbo": "https://www.together.ai/blog/meta-llama-3-1",
    "meta-llama-3.1-8b-instruct-turbo": "https://www.together.ai/blog/meta-llama-3-1",
    "mistral-large-2407": "https://huggingface.co/mistralai/Mistral-Large-Instruct-2407",
    "mistral-small-2402": "https://docs.mistral.ai/getting-started/models/",
    "mixtral-8x22b-instruct-v0.1": "https://huggingface.co/mistralai/Mixtral-8x22B-Instruct-v0.1",
    "o1-mini-2024-09-12": "https://platform.openai.com/docs/guides/reasoning",
    "o1-preview-2024-09-12": "https://platform.openai.com/docs/guides/reasoning",
    "open-mistral-nemo": "https://huggingface.co/mistralai/Mistral-Nemo-Instruct-2407",
    "phi-3-medium-128k-instruct": "https://huggingface.co/microsoft/Phi-3-medium-128k-instruct",
    "phi-3-medium-4k-instruct": "https://huggingface.co/microsoft/Phi-3-medium-4k-instruct",
    "phi-3-mini-128k-instruct": "https://huggingface.co/microsoft/Phi-3-mini-128k-instruct",
    "phi-3-mini-4k-instruct": "https://huggingface.co/microsoft/Phi-3-mini-4k-instruct",
    "phi-3-small-128k-instruct": "https://huggingface.co/microsoft/Phi-3-small-128k-instruct",
    "phi-3-small-8k-instruct": "https://huggingface.co/microsoft/Phi-3-small-8k-instruct",
    "phi-3.5-mini-instruct": "https://huggingface.co/microsoft/Phi-3.5-mini-instruct",
    "phi-3.5-moe-instruct": "https://huggingface.co/microsoft/Phi-3.5-MoE-instruct",
    "qwen2.5-72b-instruct": "https://huggingface.co/Qwen/Qwen2.5-72B-Instruct"
};
